<template>
  <div>
    <page-header v-if="showHeader" name="Dados do Participante"/>
    <b-container>
      <b-row>
        <b-col md="8" sm="12">
          <b-row>
            <b-col sm="12">
              <b-card>
                <b-row>

                  <b-col
                    cols="21"
                    xl="6"
                    class="d-flex justify-content-between flex-column"
                  >
                    <!-- User Avatar & Action Buttons -->
                    <div class="d-flex justify-content-start">
                      <b-avatar
                        :src="participant.avatar"
                        :text="avatarText(participant.name)"
                        size="104px"
                        rounded
                      />
                      <div class="d-flex flex-column ml-1">
                        <div class="mb-1">
                          <h4 class="mb-0">
                            {{ participant.name }}
                          </h4>
                          <span class="card-text">{{ participant.email }}</span>
                        </div>
                      </div>
                    </div>

                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                  >
                    <table class="mt-2 mt-xl-0 w-100">
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="AtSignIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Email</span>
                        </th>
                        <td class="pb-50">
                          {{ participant.email }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon icon="UserIcon"
                                        class="mr-75"
                          />
                          <span class="font-weight-bold">Username</span>
                        </th>
                        <td class="pb-50">
                          {{ participant.username }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="FileIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Documento</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ participant.document | DocumentPersonBr }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="PhoneIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Telefone</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ participant.phone }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="StarIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Data de Nascimento</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ participant.birthdate }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="FlagIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Cidade</span>
                        </th>
                        <td class="pb-50">
                          {{ participant.city }}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <feather-icon
                            icon="MapPinIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Estado</span>
                        </th>
                        <td>
                          {{ participant.state }}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Data de Cadastro</span>
                        </th>
                        <td>
                          {{ participant.created_at | moment('DD/MM/YYYY') }}
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col sm="12">
              <participant-number-list :participant="participant.uuid" :promotion="promotion.uuid"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4" sm="12" rowspan="3">
          <participant-promotion-history :participant="participant.uuid" :promotion="promotion.uuid"/>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import ParticipantNumberList from '@/views/participants/ParticipantNumberList.vue'
import ParticipantPromotionHistory from '@/views/participants/ParticipantPromotionHistory.vue'

export default {
  name: 'ParticipantShow',
  components: {
    ParticipantPromotionHistory,
    ParticipantNumberList,
  },
  props: {
    participantUuid: {
      type: String,
      required: false,
      default: '',
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      participant: {
        name: '',
        uuid: '',
      },
      promotion: { uuid: '' },
      currentPrizeDraw: '',
    }
  },

  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      this.$router.push('change-promotion.index')
      return
    }
    this.promotion = promotion
    this.getParticipant()
  },
  methods: {
    async getParticipant() {
      let uuid = this.participantUuid
      if (uuid === '') {
        uuid = this.$router.currentRoute.params.uuid
      }

      const promotionUuid = this.promotion.uuid
      const route = `promotions/${promotionUuid}/participants/${uuid}`
      const response = await this.$http.get(route)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.participant = response
    },
    avatarText(value) {
      if (!value) {
        return ''
      }
      const words = value.split(' ')
      const nameArray = [words[0]]
      if (words.length > 0) {
        nameArray.push(words[words.length - 1])
      }

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
  },
}
</script>

<style scoped>

</style>
